import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import PlayerApi from '@/support/playerApi';
import { ServerDataModule } from '@/store/serverData';
import Util from '@/support/utility';
import { IWizardManager, IVideo } from '@/interfaces';

@Component
export default class DeleteVideoComponent extends Vue {
    options = {
    };

    get serverData() {
        return ServerDataModule.serverData;
    }

    saveDisabled: boolean = true;

    @Prop({ default: null })
    wizard: IWizardManager;

    @Prop({ default: <any>{} })
    parent: any;

    @Prop({ default: <any>{} })
    video: IVideo;

    busy = false;

    created() {
        Debug.setDebugModule('DeleteVideo', this);
    }

    mounted() {
    }

    showExpired() {
        this.done();
    }

    showError(error: string, error2: string = null) {
        this.done();
    }

    done() {
        let video = this.video;

        Debug.log('deleteVideo started', video.studio, video.study, video.title);
        video.busy = true;

        this.parent.stopVideo(video);
        this._deleteVideo(video, event).then(() => {
            this.wizard.back();
        });
    }

    cancel() {
        this.wizard.back();
    }

    // ReSharper disable once InconsistentNaming
    async _deleteVideo(video: IVideo, event: any = null) {
        Debug.log('_deleteVideo starting', video.studio, video.id);
        video.busy = true;

        try {
            let response = await PlayerApi.deleteVideo(video);
            Debug.log("_deleteVideo SUCCESS", video.studio, video.id, video, response);

            this.parent.removeDeletedVideo(video);
            this.parent.refreshPage(true);

            Util.showToast('Deleted ' + video.title);
            return true;
        } catch (err) {
            let message = err.message || 'ERROR';
            Debug.error("_deleteVideo FAILED", video.studio, video.id, video, message);
            Util.showToast('Failed to delete video' + video.title, true);
            return null;
        } finally {
            video.busy = false;
        }
    }


}
